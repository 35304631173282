import { Link, withPrefix } from "gatsby";
import React, { Fragment } from "react";
import { namesMap } from "../../templates/names";
// @ts-ignore
import { slugFromTag } from "../../js/utils-commonjs";

const formatDate = (date: string) => {
  const dt = new Date(date);
  const year = dt.getFullYear();
  const month = String(dt.getMonth() + 1);
  const month2 = month.length === 1 ? `0${month}` : month;
  const day = String(dt.getDate());
  const day2 = day.length === 1 ? `0${day}` : day;

  return `${year}-${month2}-${day2}`;
};

const BlogPostTagLinks = ({ tags }: { tags: string[] }) => {
  return (
    <>
      {tags &&
        tags.map((tag, index) => (
          <Fragment key={tag}>
            <Link to={withPrefix(`/blog/tag/${slugFromTag(tag)}/`)}>{tag}</Link>
            {index === tags.length - 1 ? "" : ", "}
          </Fragment>
        ))}
    </>
  );
};

export const BlogPostMeta = ({
  date,
  author,
  special_byline,
  tags,
}: {
  date: string;
  author: string;
  special_byline: string;
  tags: string[];
}) => {
  // Show the default byline based on the author, unless it is overridden by a
  // special byline string from the frontmatter.
  const authorName = namesMap[author];
  const byline = special_byline || (authorName ? `By ${authorName}` : null);

  return (
    <>
      {formatDate(date)} &mdash; {byline} &mdash;{" "}
      <BlogPostTagLinks tags={tags} />
    </>
  );
};
