import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/parts/Layout";
import { BlogPostList } from "../components/parts/BlogPostList";
import { snProps } from "../js/utils";

import * as blogStyles from "../components/global-styles/blog.module.css";

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`;

  return (
    <Layout
      title={tag}
      description="Tag page description."
      location={{ pathname: tag }}
      isBlog={true}
      {...snProps}
    >
      <h1 className={`title is-3 ${blogStyles.blogTitle}`}>
        The Clairnote Blog
      </h1>
      <h2 className={`title is-6 ${blogStyles.archivePageTitle}`}>
        {tagHeader}
      </h2>
      <BlogPostList edges={edges} />
    </Layout>
  );
};

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              date: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              path: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};

export default Tags;

export const pageQuery = graphql`
  query ($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { ne: true }, tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            date
            author
            special_byline
            tags
          }
          fields {
            path
          }
          excerpt
        }
      }
    }
  }
`;
