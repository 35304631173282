import React from "react";
import { Link } from "gatsby";
import { BlogPostMeta } from "./BlogPostMeta";

import * as blogStyles from "../global-styles/blog.module.css";

interface BlogPostNode {
  // TODO: which is it for ID, string or number?
  id: string | number;
  frontmatter: {
    title: string;
    date: string;
    author: string;
    special_byline: string;
    tags: string[];
  };
  fields: {
    path: string;
  };
  excerpt: string;
}

interface BlogPostListArg {
  edges: Array<{
    node: BlogPostNode;
  }>;
}

export const BlogPostList = ({ edges }: BlogPostListArg) => (
  <>
    {edges.map(({ node }) => {
      const { title, date, author, special_byline, tags } = node.frontmatter;
      return (
        <article key={title}>
          <h1 className={`title is-5 ${blogStyles.blogListTitle}`}>
            <Link to={node.fields.path}>{title}</Link>
          </h1>
          <div>
            <p className={blogStyles.blogListPostText}>{node.excerpt}</p>
          </div>
          <footer className={blogStyles.blogFooter}>
            <BlogPostMeta
              date={date}
              author={author}
              special_byline={special_byline}
              tags={tags}
            />
          </footer>
        </article>
      );
    })}
  </>
);
